<template>
  <div class="Viewed bg-white p-10" style="min-height: 100%">
    <FavoriteList ></FavoriteList>
  </div>
</template>

<script>
import FavoriteList from '@/views/User/Favorite/FavoriteList'
/**
 * @author  XuHongli
 * @date  2022/8/31 15:44
 * @version 1.0
 * @description 我的收藏
 */
export default {
  name: 'Viewed',
  components: { FavoriteList },
  data() {
    return {
    }
  },
}
</script>

<style scoped lang="scss">
.Viewed{
}
</style>
