<template>
  <div class="FavoriteList" >
    <p class="common-tip-title mb-20 mt-10">我的收藏</p>
    <div class="p-10 px-20 flex-row flex-row-between">
      <div>
        <a-button style="width: 100px;background-color: $color-primary;" class="mr-10" size="small" @click="selectAll">
          <span v-if="!tipStatus || checkedList.length == 0 || goods.length ==0">全选</span>
          <span v-else>取消</span>
        </a-button>
      </div>
      <div class="flex-row">
        <el-button @click="handleDelete" size="small" style="margin-right:20px;">一键删除</el-button>
        <el-button @click="handleAsync" size="small" type="primary">一键入库</el-button>
      </div>
    </div>
    <div class="tip" v-show="tipStatus && checkedList.length !==0">
      <div>
        <a-icon type="info-circle" theme="filled"/>
      </div>
      <div>
        已选择全部商品：{{ checkedList.length }}件
        <a-icon type="info-circle" theme="filled" />
      </div>
    </div>
    <a-spin :spinning="loading" tip="Loading...">
      <a-checkbox-group v-model="checkedList" @change="onChange">
        <div class="goods">
          <a-card
            v-for="(item, index) in goods"
            :key="index"
            :hoverable="true"
            class="goodsCard"
            @click="$router.push('/commodity/' + item.spuId)">
            <div @click.stop="{}">
              <a-checkbox :value="item.spuId" class="checkbox"></a-checkbox>
            </div>
            <img
              slot="cover"
              :src="item.supplyProduct.imgUrl"
              alt="example"
            />
            <a-tooltip :title="item.supplyProduct.name" placement="top">
              <a-card-meta :title="item.supplyProduct.name">
                <template slot="description">
                  <div class="card">
                    <span style="color: #d11514;font-size: 16px;font-weight: 600;">￥{{ item.supplyProduct.tradePrice }}</span>
                    <span style="color: #000"><span style="color: rgba(9, 98, 234, 1);font-size: 14px;">{{ item.supplyProduct.sale }}+</span> <span style="color: rgba(185, 185, 185, 1);font-size: 12px;">月销</span></span>
                  </div>
                  <!--                <div class="btn">-->
                  <!--                  &lt;!&ndash; <a-icon :theme="item.supplyProduct.isFavorite === 1 ? 'twoTone' : 'outlined' " class="heart" two-tone-color="#eb2f96" type="heart" @click.stop="handleHeart(item,index)" /> &ndash;&gt;-->
                  <!--                  <div class="img">-->
                  <!--                    <img :src="require('@/assets/icon/xin.png')" alt="" srcset="" @click.stop="handleHeart(item,index)">-->
                  <!--                  </div>-->
                  <!--                </div>-->
                </template>
              </a-card-meta>
            </a-tooltip>

          </a-card>
        </div>
      </a-checkbox-group>

      <div class="flex-row flex-row-center flex-col-center h-full">
        <a-empty v-if="goods.length === 0 && loading === false"/>
      </div>

      <!-- 分页器   -->
      <div v-show="goods.length > 0" class="flex-row" style="flex-direction: row-reverse;padding: 10px">
        <a-pagination
          v-model="queryParam.current"
          :page-size="queryParam.size"
          :page-size-options="pageSizeOptions"
          :total="total"
          show-size-changer
          @change="onChangePages"
          @showSizeChange="onShowSizeChange"
        >
        </a-pagination>
      </div>

    </a-spin>

    <!-- 模态框1 删除的 -->
    <a-modal
      v-model="modal2Visible1"
      :cancel-button-props="{ props: { disabled: true } }"
      centered
      width="440px"
      @ok="() => (modal2Visible1 = false)"
    >
      <template slot="footer">
        <a-button key="back" @click="modal2Visible1 = false" style="color:$color-main;width: 80px;">
          取消
        </a-button>
        <a-button key="submit" type="primary" @click="deleteSelectGoods" style="width: 120px;" >
          确认删除
        </a-button>
      </template>
      <div class="box" v-if="selectId== 1">
        <div class="title">确认删除</div>
        <div class="text">
          确认删除选中（{{ checkedList.length }}个）商品，删除操作不可逆。
        </div>
      </div >
      <div class="box" v-if="selectId== 2">
        <div class="title">一键删除</div>
        <div class="text">
          确认删除<span>我的收藏里</span>的<span>全部商品</span>，此操作<span>不可逆。</span>
        </div>
      </div >
    </a-modal>



  </div>
</template>

<script>/**
 * @author  XuHongli
 * @date  2022/9/5 11:32
 * @version 1.0
 * @description
 */
import { getUserFavoriteList } from '@/api/Favorite'
import { favoriteProduct } from '@/api/Product'
import { addSelectGoods,deleteSelectGoods, deleteSelectGoodsAll, syncGoods } from '@/api/SelectGoods'
import { Modal } from 'ant-design-vue'
import { mapGetters } from 'vuex'

export default {
  name: 'FavoriteList',
  data() {
    return {
      loading: true,
      pageSizeOptions: ['10', '20', '30'],
      checkedList: [],
      goods: [],
      queryParam: {
        current: 1,
        size: 10,
        type: 2,// 用户足迹类型 1:足迹 2:收藏
        descs: 'update_time'
      },
      total: 2,
      tipStatus:false,
      modal2Visible1:false,
      selectId:1,
      isSuccess:true
    }
  },
  computed: {
    ...mapGetters(['userInfo', 'infoProfect']),
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      if (Object.keys(this.userInfo).length > 0 && this.infoProfect === 1) {
        getUserFavoriteList(this.queryParam).then((res) => {
          console.log('res', res)
          this.loading = false
          this.goods = res.data.records
          this.total = res.data.total
        })
      }
    },
    selectAll() {
      this.tipStatus = !this.tipStatus
      if(this.tipStatus){
        this.selectId = 2
        this.checkedList = this.goods.map((item) => {
        return item.spuId
      })
      }else{
        this.selectId = 1
        this.checkedList = []
      }
    },
    handleDelete(){
      if(this.checkedList.length === 0 ||this.checkedList === []){
        this.modal2Visible1 = false
        this.$message.warning('未选择任何商品！')
      }else{
        this.modal2Visible1 = true
      }
    },
    handleAsync(){
      if(this.checkedList.length === 0||this.checkedList === []){
        this.modal2Visible2 = false
        this.$message.warning('未选择任何商品！')
      }else{
        this.$AddGroupModal({ ids: this.checkedList, handleOk: () => { this.checkedList = []; this.init() } })
      }
    },
    deleteSelectGoods() {
      // if (this.checkedList.length === 0) {
      //   this.$notification.error({
      //     message: `移库失败`,
      //     description:
      //       `您还未选择产品.`,
      //   })
      //   return
      // }
      this.modal2Visible1 = false
      let data = {
        deleteAll: 0,
        productIds: this.checkedList
      }
      deleteSelectGoodsAll(data).then(() => {
        this.$notification.success({
          message: `移库成功`,
          description:
            `您已成功移除产品.`,
        })
        this.checkedList = []
        getUserFavoriteList(this.queryParam).then((res) => {
        console.log('res', res)
        this.loading = false
        this.goods = res.data.records
        this.total = res.data.total
        })
      })
    },
    handleHeart(item, index) {
      favoriteProduct(item.spuId).then(() => {
        item.supplyProduct.isFavorite === 1 ? item.supplyProduct.isFavorite = 0 : item.supplyProduct.isFavorite = 1
        getUserFavoriteList(this.queryParam).then((res) => {
          this.loading = false
          this.goods = res.data.records
          this.total = res.data.total
        })
      })
    },
    onChange(v) {
      console.log(v)
    },
    onChangePages(page, pageSize) {
      this.queryParam.current = page
      this.queryParam.size = pageSize
      this.loading = true
      getUserFavoriteList(this.queryParam).then((res) => {
        this.loading = false
        this.goods = res.data.records
        this.total = res.data.total
      })
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.current = 1
      this.queryParam.size = pageSize
      this.loading = true
      getUserFavoriteList(this.queryParam).then((res) => {
        this.loading = false
        this.goods = res.data.records
        this.total = res.data.total
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.box{
  position: relative;
  height: 80px;
  .title{
    position: absolute;
    top: 20px;
    color: #b7507a;
    font-size: 16px;
    font-weight: 600;
  }
  .text{
    position: absolute;
    top: 50px;
    color: #1a1616;
    font-weight: 500;
    span{
      color: #02c68c;
    }
  }
}
.tip{
  margin: 0 20px;
  // width: 97%;
  border: 1px solid #02c68c;
  color: #02c68c;
  // padding: 0 0 0 15px;
  display: flex;
  padding-left: 15px;
  background-color: #c7f1e5;
}
.tip div:nth-child(2){
    margin-left: 15px;
}
.card{
  display: flex;
  justify-content: space-between;
}
.goods{
  padding: 0 20px 20px 0;
  display: flex;
  flex-wrap: wrap;
  .goodsCard {
    position: relative;
    margin: 10px 9px;
    width: 251px;
    height: 339px;
    box-shadow: 3.4px 3.4px 2.7px rgba(0, 0, 0, 0.016),
    8.7px 8.7px 6.9px rgba(0, 0, 0, 0.025),
    17.7px 17.7px 14.2px rgba(0, 0, 0, 0.03),
    36.5px 36.5px 29.2px rgba(0, 0, 0, 0.034);
    border-radius: 5px;
    img{
      width: 238px;
      height: 238px;
      padding: 10px 0 0 10px;
      border-radius: 10px;
      object-fit: cover;
    }

    .btn{
      display: flex;
      justify-content: right;
      .img{
        width: 40px;
        height: 40px;
        img{
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }
  .checkbox{
    position: absolute;
    right: 10px;
    top: 10px;
    transform: scale(1.5);
  }
}
</style>
